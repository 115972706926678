import React, { useState, useEffect } from "react";
import { Steps, Card, Spin, Form, Button, Row, Modal } from "antd";
import PropTypes from "prop-types";
import moment from "moment";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { PlusOutlined } from "@ant-design/icons";
import HeadBreadCrumb from "../../components/HeadBreadCrumb";
import emptyValues from "./add/emptyValues";
import VisitInformations from "./VisitInformations";
import Clinical from "../../components/medicalRecord/clinical";
import BloodSample from "./BloodSample";
import {
  updateVisitAsync,
  getAllVisitsByPatientAsync,
} from "../../redux/visits/actions";
import { parseVisitName } from "../../helpers/functions";
import AddBloodSample from "./SamplesSection/add";
import QueryModal from "../../components/QueryModal/index";
import BloodSamples from "./SamplesSection/index";
//import FluidsNumber from "./add/FluidsNumber";
import "./styles.css";

const { Step } = Steps;

const SelectedVisit = ({
  contentIsLoading,
  visit,
  selectedVisit,
  handleGoBack,
  updateVisitAsync,
  user,
  listVisitsByPatient,
  getAllVisitsByPatientAsync,
}) => {
  const [current, setCurrent] = useState(0);
  const [values, setValues] = useState(emptyValues);
  const [openQueryModal, setOpenQueryModal] = useState(false);
  const [selectedField, selectField] = useState();
  const [clinicalForm] = Form.useForm();
  const [visitInformationsForm] = Form.useForm();
  const [bloodSampleForm] = Form.useForm();
  const [bloodSamples, setBloodSamples] = useState([]);
  const [showAdd, setShowAdd] = useState(false);
  const [showAddSample, setShowAddSample] = useState(false);
  const [selectedBloodSample, setSelectedBloodSample] = useState("");

  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    setIsModalVisible(true);
  }, [selectedBloodSample]);

  const mapFiedFamilyToForm = {
    bloodSample: bloodSampleForm,
    visitInformations: visitInformationsForm,
    clinical: clinicalForm,
  };

  useEffect(() => {
    getAllVisitsByPatientAsync(selectedVisit.patient._id);
  }, [selectedVisit.patient._id]);

  useEffect(() => {
    if(current === 2){
      setSelectedBloodSample("firstTube")
    }
    return ()=>{
      setSelectedBloodSample("")
    }
  }, [current]);

  // const onChange = (name, value) => {
  //   // setValues({ ...values, [name]: value });
  //   updateVisitAsync(selectedVisit._id, {
  //     fieldFamily: name,
  //     field: {
  //       name,
  //       value,
  //     },
  //   });
  // };

  const changeProp = (a, fieldName, prop, value) => {
    setValues({
      ...values,
      [fieldName]: {
        ...values[fieldName],
        [prop]: value,
      },
    });
  };

  const handleUpdateField = (fieldFamily, fieldName, prop, value, field) => {
    console.log(
      "fieldFamily, fieldName, prop, value",
      fieldFamily,
      fieldName,
      prop,
      value,
      field
    );
    let currentForm;
    switch (current) {
      case 0:
        currentForm = "visitInformations";
        break;
      case 1:
        currentForm = "clinical";
        break;
      default:
        currentForm = "bloodSample";
        break;
    }

    const form = mapFiedFamilyToForm[fieldFamily]
      ? mapFiedFamilyToForm[fieldFamily]
      : mapFiedFamilyToForm[currentForm];

    console.log("[debug] undefined form ? - ", {
      mapFiedFamilyToForm,
      fieldName,
      currentForm,
      form,
    });

    switch (prop) {
      case "value":
        setIsModalVisible(false);
        const val = form.getFieldValue(fieldName);
        console.log("[debug] disappearing field - ", { val, fieldName });
        const field = {
          ...values[fieldName],
          value: val,
          readOnly: true,
        };

        const body = {
          field,
          visitNumber: visit,
        };

        if (fieldFamily === "bloodSample") {
          let index = null;
          values.bloodSamples.forEach((e, idx) => {
            if (e[selectedBloodSample]) {
              index = idx;
            }
          });
          body.fieldFamily = fieldFamily;
          console.log("selectedBloodSimple",selectedBloodSample)
          console.log("selectedBloodSimple",index)
          body.bloodSampleKey = values.bloodSamples[index].key;
        } else {
          body.fieldFamily = fieldName;
        }
        updateVisitAsync(selectedVisit._id, body);
        break;
      case "cancel": // NOTE FIRST 2 TESTS ARE FOR TIMEPICKER && datetime picker (They accept moment DateTime as a value)
        switch (fieldName) {
          case "sampleTime":
            const newVals = values.bloodSamples.map((e)=>{
              if(e[selectedBloodSample]){
  
                e[fieldName].readOnly = true
              }
              return e
            })
            setValues({...values, [bloodSamples] : newVals });
            break;
          case "firstTube":
            form.setFieldsValue({
              [fieldName]: moment(
                selectedVisit.bloodSamples[0].fieldName.value
              ),
            });
            break;
          case "secondTube":
            form.setFieldsValue({
              [fieldName]: moment(
                selectedVisit.bloodSamples[1].fieldName.value
              ),
            });
            break;
          case "thirdTube":
            form.setFieldsValue({
              [fieldName]: moment(
                selectedVisit.bloodSamples[2].fieldName.value
              ),
            });
            break;
          case "fourthTube":
          case "fifthTube":
          case "SixthTube":
          case "seventhTube":
          case "eighthTube":
          case "ninthTube":
          case "tenthTube":
          case "eleventhTube":
          case "twelfthTube":
          case "Thirteenth":
          case "twentyfourth":
          case "thirtysixth":
          case "fourtyeigth":
            form.setFieldsValue({
              [fieldName]: selectedVisit.bloodSamples[0][fieldName].value,
            });
            break;
          default:
            if (fieldFamily === "visitInformations") {
              form.setFieldsValue({
                [fieldName]: {
                  date: selectedVisit[fieldName].value.date,
                  time: moment(selectedVisit[fieldName].value.time),
                },
              });
            } else {
              form.setFieldsValue({
                [fieldName]: selectedVisit[fieldName].value,
              });
            }
            break;
        }

        changeProp(fieldFamily, fieldName, "readOnly", true);
        break;
      case "readOnly":
        if (fieldName === "sampleTime" || fieldFamily === "bloodSample") {
          const newVals = values.bloodSamples.map((e)=>{
            console.log(fieldFamily, fieldName, prop, value, field)
            if(e[selectedBloodSample]){

              e[fieldName].readOnly = false
            }
            return e
          })
          setValues({...values, [bloodSamples] : newVals });
        } else {
          changeProp(fieldFamily, fieldName, prop, value);
        }
        break;
      case "query":
        let fieldToSelect = selectedVisit[fieldName];

        if (fieldFamily === "bloodSample") {
          const bs = Object.entries(selectedVisit.bloodSamples).find(
            ([key, value]) => {
              return value[fieldName];
            }
          );
          if (bs && bs.length >= 2) fieldToSelect = bs[1][fieldName];
        }

        selectField({ ...fieldToSelect, fieldFamily });
        setOpenQueryModal(true);
        break;
      default:
        changeProp(fieldFamily, fieldName, prop, value);
        updateVisitAsync(selectedVisit._id, {
          field: {
            ...values[fieldName],
            [prop]: value,
          },
          bloodSampleKey: values.bloodSamples[0]
            ? values.bloodSamples[0].key
            : "",
          fieldFamily,
          visitNumber: visit,
        });
    }
  };

  const showStep = () => {
    if (visit === "j0" || visit === "j7") {
      switch (current) {
        case 0:
          return (
            <VisitInformations
              onUpdate={handleUpdateField}
              values={values}
              form={visitInformationsForm}
              visit={visit}
            />
          );
        // case 1:
        // return <FluidsNumber onChange={onChange} values={values} />;
        case 1:
          return (
            <div style={{ maxWidth: 600 }}>
              <Clinical
                onUpdate={handleUpdateField}
                showHeightWeight={false}
                values={values}
                form={clinicalForm}
              />
            </div>
          );
        case 2:
          return (
            <div style={{ maxWidth: 700 }}>
              <BloodSample
                onUpdate={handleUpdateField}
                values={values}
                form={bloodSampleForm}
                nameProps={"firstTube"}
              />
            </div>
          );
        case 3:
          return (
            <BloodSamples
              bloodSamples={bloodSamples}
              visit={visit}
              setSelectedBloodSample={setSelectedBloodSample}
            />
          );
        // case 4:
        //   return (
        //     <div style={{ maxWidth: 700 }}>
        //       <BloodSample
        //         onUpdate={handleUpdateField}
        //         values={values}
        //         form={bloodSampleForm}
        //         nameProps={"thirdTube"}
        //       />
        //     </div>
        //   );
        case 4:
          return (
            <div>
              {/* {user && user.type === 1 ? (
                <Button
                  onClick={() => setShowAdd(true)}
                  shape="round"
                  className="m-top-medium m-bottom-large"
                  type="primary"
                  size="medium"
                  icon={<PlusOutlined />}
                >
                  Ajouter un prélèvement
                </Button>
              ) : null} */}
              <BloodSamples
                bloodSamples={bloodSamples}
                visit={visit}
                setSelectedBloodSample={setSelectedBloodSample}
              />
              <AddBloodSample
                open={showAdd}
                handleClose={() => setShowAdd(false)}
              />
            </div>
          );
        default:
          return (
            <div>
              {" "}
              You have reached the jackpot ! Contact me at firas@katomi.co to
              get your reward !{" "}
            </div>
          );
      }
    } else {
      switch (current) {
        case 0:
          return (
            <VisitInformations
              values={values}
              form={visitInformationsForm}
              visit={visit}
              onUpdate={handleUpdateField}
            />
          );
        // case 1:
        //     return <FluidsNumber onChange={onChange} values={values} />;
        case 1:
          return (
            <div>
              {showAddSample ? (
                <Button
                  onClick={() => setShowAdd(true)}
                  shape="round"
                  className="m-top-medium m-bottom-large"
                  type="primary"
                  size="medium"
                  icon={<PlusOutlined />}
                >
                  Ajouter un prélèvement
                </Button>
              ) : null}
              <BloodSamples
                bloodSamples={bloodSamples}
                visit={visit}
                setSelectedBloodSample={setSelectedBloodSample}
                nameProps={selectedBloodSample}
              />
              <AddBloodSample
                open={showAdd}
                handleClose={() => setShowAdd(false)}
              />
            </div>
          );
        default:
          return <div />;
      }
    }
  };

  useEffect(() => {
    if (selectedVisit) {
      if (visit === "j0" || visit === "j7") {
        const { sampleTime, firstTube } = selectedVisit.bloodSamples[0];
        const { secondTube } = selectedVisit.bloodSamples[1];
        const { thirdTube } = selectedVisit.bloodSamples[2];
        const { fourthTube } = selectedVisit.bloodSamples[3];
        const { fifthTube } = selectedVisit.bloodSamples[4];
        const { SixthTube } = selectedVisit.bloodSamples[5];
        const { seventhTube } = selectedVisit.bloodSamples[6];
        const { eighthTube } = selectedVisit.bloodSamples[7];
        const { ninthTube } = selectedVisit.bloodSamples[8];
        const { tenthTube } = selectedVisit.bloodSamples[9];
        const { eleventhTube } = selectedVisit.bloodSamples[10];
        const { twelfthTube } = selectedVisit.bloodSamples[11];
        const { Thirteenth } = selectedVisit.bloodSamples[12];
        setValues({
          ...selectedVisit,
          sampleTime,
          firstTube,
          secondTube,
          thirdTube,
          fourthTube,
          fifthTube,
          SixthTube,
          seventhTube,
          eighthTube,
          ninthTube,
          tenthTube,
          eleventhTube,
          twelfthTube,
          Thirteenth,
        });
        const keys = Object.keys(selectedVisit);
        // eslint-disable-next-line array-callback-return
        keys.map((key) => {
          switch (key) {
            case "consultationDate":
            case "admissionDate":
            case "lastMeal":
            case "lastDrink":
              visitInformationsForm.setFieldsValue({
                [key]: {
                  date: selectedVisit[key].value
                    ? selectedVisit[key].value.date
                    : null,
                  time: selectedVisit[key].value
                    ? moment(selectedVisit[key].value.time)
                    : null,
                },
              });
              break;
            case "systolique":
            case "diastolique":
            case "bodyTemperature":
              clinicalForm.setFieldsValue({ [key]: selectedVisit[key].value });
              break;
            case "bloodSamples":
              console.log(
                "sampleTime.value",
                bloodSampleForm.getFieldValue("sampleTime")
              );
              bloodSampleForm.setFieldsValue({
                sampleTime: sampleTime ? moment(sampleTime.value) : null,
                firstTube: firstTube.value,
                secondTube: secondTube ? secondTube.value : "",
              });
              break;
            default:
              break;
          }
        });
      } else if(visit === "j9" || visit === "j2"){
        const { sampleTime, fourtyeigth } = selectedVisit.bloodSamples[0];
        // const fourtyeigth = selectedVisit.bloodSamples[1]
        //   ? selectedVisit.bloodSamples[1].thirtysixth
        //   : {};
        setValues({ ...selectedVisit, sampleTime, fourtyeigth });
        const keys = Object.keys(selectedVisit);
        // eslint-disable-next-line array-callback-return
        keys.map((key) => {
          switch (key) {
            case "consultationDate":
              // case 'lastMeal':
              // case 'lastDrink':
              visitInformationsForm.setFieldsValue({
                [key]: {
                  date: selectedVisit[key].value.date,
                  time: moment(selectedVisit[key].value.time),
                },
              });
              break;
            default:
              break;
          }
        });
      }else{
        const { sampleTime, twentyfourth } = selectedVisit.bloodSamples[0];
        const thirtysixth = selectedVisit.bloodSamples[1]
          ? selectedVisit.bloodSamples[1].thirtysixth
          : {};
        setValues({ ...selectedVisit, sampleTime, twentyfourth, thirtysixth });
        const keys = Object.keys(selectedVisit);
        // eslint-disable-next-line array-callback-return
        keys.map((key) => {
          switch (key) {
            case "consultationDate":
              // case 'lastMeal':
              // case 'lastDrink':
              visitInformationsForm.setFieldsValue({
                [key]: {
                  date: selectedVisit[key].value.date,
                  time: moment(selectedVisit[key].value.time),
                },
              });
              break;
            default:
              break;
          }
        });
      }
      setBloodSamples(selectedVisit.bloodSamples);
    }
  }, [selectedVisit]);

  useEffect(() => {
    if (user && user.type === 1) {
      if (visit === "j1" || visit === "j8") {
        if (selectedVisit.bloodSamples.length !== 2) {
          setShowAddSample(true);
        } else {
          setShowAddSample(false);
        }
      } else if (visit === "j2" || visit === "j9") {
        if (selectedVisit.bloodSamples.length !== 1) {
          setShowAddSample(true);
        } else {
          setShowAddSample(false);
        }
      } else if (selectedVisit.bloodSamples.length !== 12) {
        setShowAddSample(true);
      } else {
        setShowAddSample(false);
      }
    } else {
      setShowAddSample(false);
    }
  }, [selectedVisit]);

  if (!selectedVisit) return <div />;

  return (
    <div className="section-content">
      <HeadBreadCrumb
        firstSectionName="Visites"
        secondSectionName={parseVisitName(selectedVisit.visitNumber)}
        thirdSectionName={
          user && user.type === 1
            ? `${selectedVisit.patient.lastName.value} ${selectedVisit.patient.firstName.value}`
            : selectedVisit.patient.customId
        }
        handleGoBack={handleGoBack}
      />
      <Spin spinning={contentIsLoading}>
        <Row>
          {/* <Col span={18}> */}
          <Card className="formWidth">
            <Steps
              current={current}
              onChange={setCurrent}
              size="small"
              className="m-bottom-medium"
            >
              <Step title="Informations" />
              {/* <Step title="Nombre de fluides" /> */}
              {visit === "j0" || visit === "j7" ? (
                <>
                  <Step title="Clinique" />
                  <Step title="Pré-dose" />
                </>
              ) : null}
              <Step title="Prélèvements" />
            </Steps>
            <div className="m-top-medium" style={{ padding: 24 }}>
              {showStep()}
            </div>
          </Card>
          {/* </Col>
          <Col span={6}>
            {listVisitsByPatient &&
              listVisitsByPatient.map((item, i) => (
                <Timeline.Item key={audit._id}>
                  <Tree treeData={prepareTree(item, i)} />
                </Timeline.Item>
              ))}
          </Col> */}
        </Row>
      </Spin>
      <QueryModal
        call={selectedVisit}
        open={openQueryModal}
        field={selectedField}
        handleClose={() => setOpenQueryModal(false)}
      />
      <>
        {selectedBloodSample && current !==2 && (
          <Modal
            width={640}
            title="Prélèvements"
            visible={isModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
          >
            <BloodSample
              onUpdate={handleUpdateField}
              values={values}
              form={bloodSampleForm}
              nameProps={
                selectedBloodSample ? selectedBloodSample : "firstTube"
              }
            />
          </Modal>
        )}
      </>
    </div>
  );
};

SelectedVisit.propTypes = {
  contentIsLoading: PropTypes.bool.isRequired,
  visit: PropTypes.string.isRequired,
  handleGoBack: PropTypes.func.isRequired,
  selectedVisit: PropTypes.object.isRequired,
  updateVisitAsync: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  getAllVisitsByPatientAsync: PropTypes.func.isRequired,
};

const stateToProps = (state) => ({
  contentIsLoading: state.loading.contentIsLoading,
  selectedVisit: state.visits.selectedVisit,
  user: state.auth.user,
  listVisitsByPatient: state.visits.visitsPatient,
});

const dispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateVisitAsync,
      getAllVisitsByPatientAsync,
    },
    dispatch
  );

export default connect(stateToProps, dispatchToProps)(SelectedVisit);
