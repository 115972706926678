import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Tooltip } from 'antd';
import {
    CheckCircleOutlined,
    PauseCircleOutlined,
    QuestionCircleOutlined,
    EditOutlined,
    CloseCircleOutlined,
    HistoryOutlined,
    PauseCircleFilled,
    CheckCircleFilled,
    QuestionCircleFilled,
} from '@ant-design/icons';
import { selectAuditAsync, selectPatient } from '../../redux/patients/actions';
import { selectVisit } from '../../redux/visits/actions';
import { selectAdverse } from '../../redux/adverse/actions';
import './styles.css';


const FieldActions = ({
    user,
    selectedPatient,
    selectedVisit,
    onUpdate,
    field,
    fieldFamily,
    visit,
    selectAuditAsync,
    nameProps,
}) => {
    if (!field) return <div />;
    const nothingSelected = !selectedPatient && !selectedVisit;
    if (user.type === 1) {
        console.log("---------------------------readOnly--------------")
        console.log("field.readOnly",field.readOnly)
        return (
            <div>
                { field.readOnly || nothingSelected || field.readOnly !== false  ? (
                    <Tooltip className="m-left-medium" title="Modifier ce champ">
                        <Button
                            type="text"
                            shape="circle"
                            disabled={nothingSelected || field.readOnly === false}
                            icon={<EditOutlined className="field-action-icon color-blue" />}
                            onClick={() => onUpdate(fieldFamily, field.name, 'readOnly', false, nameProps)}
                        />
                    </Tooltip>
                ) : (
                    <div>
                        <Tooltip className="m-left-medium" title="Enregistrer">
                            <Button
                                type="text"
                                shape="circle"
                                disabled={nothingSelected}
                                icon={<CheckCircleOutlined className="field-action-icon color-green" />}
                                onClick={() => onUpdate(fieldFamily, field.name, 'value', false)}
                            />
                        </Tooltip>
                        <Tooltip className="m-left-medium" title="Annuler">
                            <Button
                                type="text"
                                shape="circle"
                                disabled={nothingSelected}
                                icon={<CloseCircleOutlined className="field-action-icon color-red" />}
                                onClick={() => onUpdate(fieldFamily, field.name, 'cancel', false)}
                            />
                        </Tooltip>
                    </div>
                )}
            </div>
        );
    }

    const handleClickAudit = () => {
        let query;
        if (selectedPatient) {
            switch (fieldFamily) {
                case 'medicalAntecedentsProps':
                    query = `fieldFamily=medicalAntecedents&fieldName=medicalAntecedents&visit=${visit}`;
                    break;
                case 'treatmentsProps':
                    query = `fieldFamily=treatments&fieldName=treatments&visit=${visit}`;
                    break;
                default:
                    query = `fieldFamily=${fieldFamily}&fieldName=${field.name}&visit=${visit}`;
                    break;
            }
            selectAuditAsync(selectedPatient._id, query);
        }

        if (selectedVisit) {
            const visitNb = parseInt(selectedVisit.visitNumber.split('').pop(), 10);
            query = `fieldFamily=${fieldFamily}&fieldName=${field.name}&visit=${visitNb}`;
            selectAuditAsync(selectedVisit.patient._id, query);
        }
    };

    return (
        <div className="flex m-left-medium">
            <Tooltip className="m-left-medium" title={field.sdv ? 'SDV' : 'Check SDV?'}>
                <Button
                    type="text"
                    shape="circle"
                    disabled={nothingSelected}
                    icon={field.sdv ? <CheckCircleFilled className="field-action-icon color-green" /> : <CheckCircleOutlined className="field-action-icon color-green" />}
                    onClick={() => onUpdate(fieldFamily, field.name, 'sdv', !field.sdv)}
                />
            </Tooltip>
            <Tooltip className="m-left-medium" title={field.frozen ? 'Unfreeze' : 'Freeze?'}>
                <Button
                    type="text"
                    shape="circle"
                    disabled={nothingSelected}
                    icon={field.frozen ? <PauseCircleFilled className="field-action-icon color-blue" /> : <PauseCircleOutlined className="field-action-icon color-blue" />}
                    onClick={() => onUpdate(fieldFamily, field.name, 'frozen', !field.frozen)}
                />
            </Tooltip>
            <Tooltip className="m-left-medium" title="Audit trial">
                <Button
                    type="text"
                    shape="circle"
                    disabled={nothingSelected}
                    onClick={handleClickAudit}
                    icon={<HistoryOutlined className="field-action-icon color-yellow" />}
                />
            </Tooltip>
            <Tooltip className="m-left-medium" title={field.query ? 'Consulter Query' : 'Query'}>
                <Button
                    type="text"
                    shape="circle"
                    disabled={nothingSelected}
                    onClick={() => onUpdate(fieldFamily, field.name, 'query')}
                    icon={field.query ? <QuestionCircleFilled className="field-action-icon color-red" /> : <QuestionCircleOutlined className="field-action-icon color-red" />}
                />
            </Tooltip>
        </div>
    );
};

FieldActions.propTypes = {
    user: PropTypes.object.isRequired,
    selectedPatient: PropTypes.object,
    selectedVisit: PropTypes.object,
    onUpdate: PropTypes.func.isRequired,
    field: PropTypes.object.isRequired,
    fieldFamily: PropTypes.string.isRequired,
    selectAuditAsync: PropTypes.func.isRequired,
    visit: PropTypes.number, // REVIEW this
};

FieldActions.defaultProps = {
    selectedPatient: null,
    selectedVisit: null,
    visit: 1,
};

const stateToProps = state => ({
    user: state.auth.user,
    selectedPatient: state.patients.selectedPatient,
    selectedVisit: state.visits.selectedVisit,

});


const dispatchToProps = dispatch => bindActionCreators(
    {
        selectAuditAsync,
        selectPatient,
        selectVisit,
        selectAdverse,
    },
    dispatch,
);

export default connect(stateToProps, dispatchToProps)(FieldActions);
